import React from 'react'
import './styles/CustomPopupVolume.scss'
import ic_button_close from '../../../../../assets/bo-v3-icons/ic_button_close_popup.svg'
import ic_button_play from '../../../../../assets/bo-v3-icons/ic_playMusic.svg'
import ic_button_pause from '../../../../../assets/bo-v3-icons/ic_pauseMusic.svg'
import ic_button_next from '../../../../../assets/bo-v3-icons/ic_nextMusic.svg'
import ic_button_previous from '../../../../../assets/bo-v3-icons/ic_previousMusic.svg'
import CustomPopupVolumeContainerRange from './component/CustomPopupVolumeContainerRange'
import { AUDIO_LIST } from 'constants/audioList'
import { useDispatch, useSelector } from 'react-redux'
import { change_current_index_song } from 'actions'
import { handle_set_audio_music } from 'actions'
import { handle_is_music_on } from 'actions'

function CustomPopupVolume(props) {
  const { setShowPopupSettingAudio } = props
  const audioOptions = useSelector((state) => state?.audio)
  const dispatch = useDispatch()

  function handleNextPrevious(type) {
    let actionIndex
    if (type === 'next') {
      actionIndex = audioOptions.currentIndexSong + 1 > AUDIO_LIST.length - 1 ? 0 : audioOptions.currentIndexSong + 1
    }
    if (type === 'previous') {
      actionIndex = audioOptions.currentIndexSong - 1 < 0 ? AUDIO_LIST.length - 1 : audioOptions.currentIndexSong - 1
    }
    audioOptions.audioMusic.unload()
    dispatch(handle_set_audio_music(undefined))
    dispatch(change_current_index_song(actionIndex))
  }

  return (
    <div className="CustomPopupVolume">
      <div className="CustomPopupVolumeBlur"></div>
      <div className="CustomPopupVolumeContainer">
        <div className="CustomPopupVolumeContainerHeader">
          <p>Cài đặt âm lượng</p>
          <img
            src={ic_button_close}
            alt=""
            className="btn_close"
            onClick={() => {
              setShowPopupSettingAudio(false)
            }}
          />
        </div>

        <div className="CustomPopupVolumeContainerOption">
          <CustomPopupVolumeContainerRange nameRange="volumeVoice" title={'Giao dịch'} />
        </div>

        <div className="CustomPopupVolumeContainerMusic">
          <CustomPopupVolumeContainerRange nameRange="volumeMusic" title={'Âm nhạc'} />
          <div className="CustomPopupVolumeContainerMusicButton">
            <img
              src={ic_button_previous}
              alt=""
              onClick={() => {
                handleNextPrevious('previous')
              }}
            />
            <img
              src={!audioOptions.isMusicOn ? ic_button_play : ic_button_pause}
              alt=""
              onClick={() => {
                if (audioOptions.audioMusic.playing()) {
                  audioOptions.audioMusic.pause()
                  dispatch(handle_is_music_on(false))
                } else {
                  audioOptions.audioMusic.play()
                  dispatch(handle_is_music_on(true))
                }
              }}
            />
            <img
              src={ic_button_next}
              alt=""
              onClick={() => {
                handleNextPrevious('next')
              }}
            />
          </div>
          <div className="CustomPopupVolumeContainerMusicList">
            {AUDIO_LIST.map((item, index) => (
              <div
                key={index}
                className={`CustomPopupVolumeContainerMusicListItem ${index === audioOptions.currentIndexSong ? 'active' : ''}`}
                onClick={() => {
                  audioOptions.audioMusic.unload()
                  dispatch(handle_set_audio_music(undefined))
                  dispatch(change_current_index_song(index))
                }}>
                <span>
                  {index + 1}. {item.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomPopupVolume
