import { useRef } from 'react'
import './styles/styles.scss'
import ic_close from '../../../assets/bo-v3-icons/ic_button_close_popup.svg'
function Modal(props) {
  const modal = useRef()
  const overlayClickHandler = (e) => {
    if (e.target === modal.current) {
      props.closeDrawer()
    }
  }
  return (
    <div id={'Modal'} style={{ display: !props.open ? "none" : "block" }}>
      <div className={`modalBasicCustom ${props.open === true ? 'show' : ''} ${props.className || ''} `} onClick={overlayClickHandler} ref={modal}>
        <div className={`modalBasicCustom-container`}>
          <img src={ic_close} alt="" className="closeButton" onClick={() => props.closeDrawer()} />
          <div className="title">{props.title}</div>
          {props.children}
        </div>
      </div>
    </div>
  )
}
export default Modal
