export const AUDIO_LIST = [
  {
    index: 0,
    name: 'Đừng Làm Trái Tim Anh Đau - Sơn Tùng MTP _ Okeda.net'
  },
  {
    index: 1,
    name: 'Ngày Đẹp Trời Để Nói Chia Tay - Lou Hoàng _ Okeda.net'
  },
  {
    index: 2,
    name: 'Thủy Triều - Quang Hùng MasterD _ Okeda.net'
  },
  {
    index: 3,
    name: 'Quả Phụ Tướng - DungHoangPham _ Okeda.net'
  },
  {
    index: 4,
    name: 'Khuê Mộc Lang - Hương Lý & Jombie _ Okeda.net'
  },
  {
    index: 5,
    name: 'Hoa Nở Bên Đường - Quang Đăng Trần _ Okeda.net'
  },
  {
    index: 6,
    name: 'Anh Thôi Nhân Nhượng Remix - Dung HoangPham _ Okeda.net'
  },
  {
    index: 7,
    name: 'Waiting For You - Mono _ Okeda.net'
  },
  {
    index: 8,
    name: 'Cẩm Tú Cầu - RayO & Huỳnh Văn _ Okeda.net'
  },
  {
    index: 9,
    name: 'Hôn Lễ Của Anh - Tuệ Ny _ Okeda.net'
  },
  {
    index: 10,
    name: 'Họa Đi Phúc Sẽ Đến - Đoàn Lâm _ Okeda.net'
  }
]
