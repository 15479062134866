import { change_volume_voice } from 'actions'
import { change_volume_music } from 'actions'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ic_volume from '../../../../../../assets/bo-v3-icons/ic_header_volume.svg'
import ic_volume_danger from '../../../../../../assets/bo-v3-icons/ic_volume_settings_danger.svg'

const reduxAction = [
  {
    name: 'volumeMusic',
    func: change_volume_music,
    default_range: 30
  },
  {
    name: 'volumeVoice',
    func: change_volume_voice,
    default_range: 100
  }
]

const CustomPopupVolumeContainerRange = ({ nameRange, title }) => {
  const dispatch = useDispatch()
  const audioOptions = useSelector((state) => state?.audio)

  function handleChangeVolume(value) {
    const func = reduxAction.find((item) => item.name === nameRange)?.func

    if (func) {
      dispatch(func(+value))
      window.localStorage.setItem(`${nameRange}UserChange`, +value)
    }
  }

  useEffect(() => {
    var rangeInput = document.getElementById(nameRange)
    const progress = rangeInput?.value || 0
    rangeInput.style.background = `linear-gradient(to right, #feb914 ${progress}%, #ccc ${progress}%)`
  }, [audioOptions[nameRange]])

  useEffect(() => {
    if (audioOptions.audioMusic) {
      audioOptions.audioMusic.volume(audioOptions.volumeMusic / 100)
    }
  }, [audioOptions.volumeMusic])

  function handleClickOnOfTypeVolume() {
    const obj = reduxAction.find((item) => item.name === nameRange)
    if (+audioOptions[nameRange] !== 0 && obj.func) {
      window.localStorage.setItem(`${nameRange}UserChange`, 0)
      dispatch(obj.func(0))
    } else {
      dispatch(obj.func(+obj.default_range))
      window.localStorage.setItem(`${nameRange}UserChange`, +obj.default_range)
    }
  }
  return (
    <div className={`CustomPopupVolumeContainerRange ${nameRange}`}>
      <div className="wrapper">
        <div className="title">{title}</div>
        <div className="range">
          <input
            type="range"
            min="0"
            max="100"
            value={audioOptions[nameRange] + ''}
            id={nameRange}
            onChange={(e) => handleChangeVolume(e.target.value)}
          />
          <div className="value">
            <img
              alt=""
              src={+audioOptions[nameRange] === 0 ? ic_volume_danger : ic_volume}
              height={24}
              width={24}
              onClick={() => {
                handleClickOnOfTypeVolume()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomPopupVolumeContainerRange
