import Modal from '../..'
import './styles.scss'
function modalVideoGuide(props) {
  return (
    <Modal open={props?.open} title={props.title} closeDrawer={props.closeDrawer}>
      <div className="modalVideoGuide">
        <div>
          <iframe src={props.src} className="iframe" />
        </div>
      </div>
    </Modal>
  )
}

export default modalVideoGuide
