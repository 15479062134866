import './index.scss'

import swal from 'sweetalert'

export default function BasicAlertPopup(title, icon, dangerMode, buttonConfirm, onConfirmButton) {
  return swal({
    icon: icon || 'success',
    buttons: [{visible: true, closeModal: true, text: ''}, buttonConfirm],
    closeOnClickOutside: false,
    className: 'basic-alert-popup',
    dangerMode: dangerMode || false,
    content: {
      element: 'div',
      attributes: {
        innerHTML: `<div style='color:#fff;'>${title}</div> `,
      },
    },
    timer: !buttonConfirm ? 3000 : undefined
  }).then(isConfirm => {
    if (isConfirm && buttonConfirm) {
      onConfirmButton()
    }
  })
}
